import React from 'react'
import { graphql } from 'gatsby'

import Index from './_index'

export default props => <Index {...props} />

export const pageQuery = graphql`
  query {
    site {
      ...SiteInformation
    }
    prismic {
      allHomepages(lang: "en-gb") {
        edges {
          node {
            meta_title
            meta_keywords
            meta_description
            page_subtitle
            content_body
            instagram_title
            _linkType
            _meta {
              id
            }
          }
        }
      }
    }
    egk: file(relativePath: { eq: "egk.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 200, maxHeight: 200) {
          ...GatsbyImageSharpFluid
          presentationWidth
          presentationHeight
        }
      }
    }
    logo: file(relativePath: { eq: "logo.png" }) {
      childImageSharp {
        fixed(width: 180) {
          ...GatsbyImageSharpFixed
        }
      }
    }
    allInstaNode(limit: 50, sort: { fields: [timestamp], order: DESC }) {
      edges {
        node {
          id
          localFile {
            childImageSharp {
              fluid(maxWidth: 300, maxHeight: 300) {
                ...GatsbyImageSharpFluid
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
  }
`
